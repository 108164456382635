#Title {
    text-align: center;
}

#SubTitle {
    margin-bottom: 4%;
}

p {
    margin-bottom: 2%;
}

.main {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    height: 100dvh;
}

.circular-progress {
    display: flex;
    align-items: center;
    justify-content: center;
}

.location-weather {
    padding: 0 1%;
    text-align: center;
    width: 16dvw;
}

.గడిచిన-సమయము {
    height: 100%;
    padding: 1%;
    width: 78dvw;
}

.సమయము {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 2%;
}

.సమయము div {
    background-color: #6DB9EF;
    border-radius: 4px;
    color: #ffffff;
    font-size: 2rem;
    height: 50px;
    margin-right: 2%;
    margin-bottom: 2%;
    padding: 2%;
    position: relative;
    text-align: center;
    width: 20%;
}

.సమయము div span {
    font-size: 1rem;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
}

.btn {
    background-color: #fff;
    border: 1px solid #000000;
    border-radius: 4px;
    cursor: pointer;
    height: fit-content;
    padding: 1%;
    width: 200px;
}

.weather-icon-temp-text {
    background-color: #6DB9EF;
    border-radius: 4px;
    padding: 1%;
    margin-bottom: 4%;
}

.weather-icon {
    float: left;
}

.temp {
    color: #fff;
    font-size: 1.2rem;
}

.status {
    color: #fff;
    font-size: 0.8rem;
}

.weather-details {
    background-color: #6DB9EF;
    border-radius: 4px;
}

.weather-details div {
    border-bottom: 1px solid lightgrey;
    margin-bottom: 4%;
    padding: 0 2%;
    position: relative;
}

.weather-details div:first-child {
    padding-top: 4%;
}

.weather-details div:last-child {
    border-bottom: none;
}

.weather-details div span {
    color: #fff;
    font-size: 0.8rem;
    position: absolute;
}

.weather-details div p {
    color: #fff;
    display: inline-block;
    font-size: 1rem;
    text-align: right;
    width: 100%;
}

footer {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 2%;
}

footer button {
    background-color: #ffffff;
    border: 1px solid #000000;
    border-radius: 4px;
    cursor: pointer;
    padding: 1%;
}

@media only screen and (max-width: 1000px) {
    .main {
        justify-content: center;
    }

    .location-weather {
        width: 50dvw;
    }

    .గడిచిన-సమయము {
        width: 100%;
    }
}

@media only screen and (max-width: 500px) {
    #Title {
        font-size: 1.5rem;
    }

    .location-weather {
        width: 80dvw;
    }

    .సమయము {
        flex-direction: column;
    }

    .సమయము div {
        width: 50%;
    }
}

@media only screen and (max-width: 400px) {
    .సమయము {
        flex-direction: column;
    }
}
